import React from 'react';
import { BrowserRouter as Router, Routes, Route, useNavigate } from 'react-router-dom';

import { 
  Box,
	Button,
	Container, 
	Typography 
} from '@mui/material';

import PublicRoutes from './publicRoutes';
import ProtectedRoutes from './protectedRoutes';
import ErrorHandler from '../components/errorHandler/ErrorHandler';

//routes
import AuthPage from '../views/authPage/AuthPage';
import HomePage from '../views/homePage/HomePage';


const MainRouter = () => {

  return (
    <Router>
      <Routes>
        <Route element={<PublicRoutes />}>
          <Route path='/' element={<HomePage />} />
          <Route path='/auth' element={<AuthPage />} />
        </Route>
        <Route element={<ProtectedRoutes />}>
          {/* <Route path='/' element={<Navigation />}>
    
          </Route>
          */}
        </Route>
        <Route path='*' element={<NotFoundPage />} />
      </Routes>
      <ErrorHandler />
    </Router>
  )
}

export default MainRouter;

// 404 page
const NotFoundPage = () => {
  const navigate = useNavigate();
  return(
		<Container maxWidth="lg">
      <Box sx={{ height: "calc(100vh)", gap: "40px" }} 
        className="flexCenterCenterColumn">
			  <Typography variant='h4'>404 - Page not found</Typography>
        <Button
          variant='contained'
          onClick={() => navigate("/")}>
          Go Back
        </Button>
      </Box>
		</Container>
  )
}
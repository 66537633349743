import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import * as actionTypes from "../../redux/actionTypes";

const ErrorHandler = () => {
  const dispatch = useDispatch();
  const state = useSelector(state => state.other);

  useEffect(() => {
    try {
      console.log(state);
      if(state.errors !== null) errorParser(state.errors);
      if(state.crashes !== null) crashParser(state.crashes);
    } catch(err) {}
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]);

  const errorParser = (data) => {


    // errors will be resolved at first instance.
    dispatch({ type: actionTypes.CLEAR_ERRORS });
  }

  const crashParser = (data) => {
    if(typeof(data) === 'string'){
      toast.error(data);
    }
    // same as errors, crashes will also be resolved at first instance.
    dispatch({ type: actionTypes.CLEAR_CRASHES });
  }

}

export default ErrorHandler;
import { createTheme } from "@mui/material";

import styles from "../global.scss";

export const theme = createTheme({
    typography: {
        fontFamily: "urbanist",
        h1: {
            fontWeight: 900,
            fontSize: "148px",
            color: styles["secondary_dark"]
        },
        h2: {
            fontWeight: 800,
            fontSize: "108px",
            color: styles["secondary_dark"]
        },
        h3: {
            fontWeight: 700,
            fontSize: "72px",
            color: styles["secondary_dark"]
        },
        h4: {
            fontWeight: 600,
            fontSize: "48px",
            color: styles["secondary_dark"]
        },
        h5: {
            fontWeight: 600,
            fontSize: "32px",
            color: styles["secondary_dark"]
        },
        h6: {
            fontWeight: 600,
            fontSize: "24px",
            color: styles["secondary_dark"]
        },
        subtitle1: {
            fontWeight: 600,
            fontSize: "20px",
            color: styles["secondary_dark"]
        },
        subtitle2: {
            fontWeight: 600,
            fontSize: "18px",
            color: styles["secondary_dark"]
        },
        body1: {
            fontWeight: 500,
            fontSize: "17px", 
            color: styles["secondary_dark"]
        },
        body2: {
            fontWeight: 500,
            fontSize: "15px",
            color: styles["secondary_dark"]
        },
        button: {
            fontWeight: 700,
            fontSize: "16px",
            color: styles["secondary_dark"]
        },
        caption: {
            fontWeight: 500,
            fontSize: "14px",
            color: styles["secondary_dark"]
        }
    },
    components: {
        MuiButton: {
            styleOverrides: {
                root: {
                    borderRadius: "12px",
                    minWidth: "120px",
                    minHeight: "50px"
                },
                text: {
                    backgroundColor: styles["primary_light"],
                    color: styles["secondary_dark"],
                    "&:hover": {
                        backgroundColor: styles["primary"]
                    }
                },
                contained: {
                    backgroundColor: styles["secondary"],
                    color: styles["primary_light"],
                    "&:hover": {
                        backgroundColor: styles["secondary_light"]
                    }
                },
                outlined: {
                    borderColor: styles["secondary_dark"],
                    color: styles["secondary_dark"],
                    "&:hover": {
                        backgroundColor: styles["primary_light"]
                    }
                }
            }
        },
        MuiOutlinedInput: {
            styleOverrides: {
                root: {
                    borderRadius: "12px",
                },
            }
        },
        MuiInputLabel: {
            styleOverrides: {
                root:{
                    color: "grey"
                }
            }
        },
        MuiPopover: {
            styleOverrides: {
                root: {
                    borderRadius: "12px",
                    pointerEvents: 'none',
                },
                paper: {
                    borderRadius: "12px",
                    pointerEvents: "auto",
                },
            }
        },
        MuiLink: {
            styleOverrides: {
                root: {
                    fontWeight: 600,
                    fontSize: "18px",
                    cursor: 'pointer'
                }
            }
        },
        MuiSwitch: {
            styleOverrides: {
                root: {
                    padding: 6,
                },
                thumb: {
                    backgroundColor: styles["secondary_light"],
                    boxShadow: 'none',
                    width: 18,
                    height: 18,
                    margin: 1,
                },
                track: {
                    border: "1.5px solid",
                    borderColor: styles["secondary"],
                    backgroundColor: "white",
                    borderRadius: 26 / 2,
                    '&::before, &::after': {
                        content: '""',
                        position: 'absolute',
                        top: '50%',
                        transform: 'translateY(-50%)',
                        width: 18,
                        height: 18,
                    },
                },
            }
        },
        MuiTableCell: {
            styleOverrides: {
                head: {
                    color: "grey"
                }
            }
        }
    }
})